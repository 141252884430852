<template>
    <div class="distributionLevel">
        <div class="flex">
            <div class="search">
                <a-input class="search-item" v-model="Name" placeholder="等级名称"></a-input>
                <a-button class="search-item" @click="_reset()">重 置</a-button>
                <a-button class="search-item" type="primary" @click="_search()">查 询</a-button>

            </div>
            <a-button  type="primary" @click="_add()">添加等级</a-button>
        </div>


        <!--<div :style="'width:'+pageWidth+'px'" v-if="tableList.length>0">-->
            <!--:scroll="{ x: 1500 }"-->
            <a-table :pagination="false" :columns="tableColumns" :data-source="tableList" >
                <div slot="CommissionFirst" slot-scope="text, record">
                    <div>{{record.CommissionFirst}}{{record.CommissionReturnType==2?'元佣金':'%的佣金'}}</div>
                   
                </div>
                <div slot="CommissionSecond" slot-scope="text, record">
                    <div>{{record.CommissionSecond}}{{record.CommissionReturnType==2?'元佣金':'%的佣金'}}</div>

                </div>
                <div slot="UpdateType" slot-scope="text, record">
                    <div v-if="record.update.length>0" v-for="(item,index) in record.update">{{index+1}}、{{item}}</div>
                    <span v-else>无</span>
                </div>
                <div slot="DropType" slot-scope="text, record">
                    <div v-if="record.drop.length>0" v-for="(item,index) in record.drop">{{index+1}}、{{item}}</div>
                    <span v-else>无</span>
                </div>
                <div slot="Id" slot-scope="text, record">
                    <span class="table-action" @click="_info(record.Id)">编辑</span>
                    <a-popconfirm title="您确定要删除吗?" @confirm="_del(record.Id)" okText="确定" cancelText="取消">
                        <span class="table-action">删除</span>
                    </a-popconfirm>

                </div>
            </a-table>
            <a-pagination v-if="total" style="margin-top: 35px;text-align: right;" v-model="Page" @change="ChangePage" :pageSize="Limit" :total="total" show-less-items/>
        <!--</div>-->

    </div>
</template>

<script>
    export default {
        name: "distributionLevel",
        data() {
            return {
                tableColumns: [
                    {
                        title: '等级名称',
                        dataIndex: 'Name',
                        key: 'Name',
                        width: 240,

                    },
                    {
                        title: '一级返佣',
                        dataIndex: 'CommissionFirst',
                        align: 'left',
                        key: 'CommissionFirst',
                        scopedSlots: {customRender: 'CommissionFirst'},
                        width: 240,
                    },
                    {
                        title: '二级返佣',
                        dataIndex: 'CommissionSecond',
                        align: 'left',
                        key: 'CommissionSecond',
                        scopedSlots: {customRender: 'CommissionSecond'},
                        width: 240,
                    },
                    {
                        title: '升级条件',
                        dataIndex: 'UpdateType',
                        align: 'left',
                        key: 'UpdateType',
                        scopedSlots: {customRender: 'UpdateType'},
                        width: 240,
                    },
                    {
                        title: '降级条件',
                        dataIndex: 'DropType',
                        align: 'left',
                        key: 'DropType',
                        scopedSlots: {customRender: 'DropType'},
                        width: 240,
                    },
                    {
                        title: '权重',
                        dataIndex: 'Weight',
                        align: 'left',
                        key: 'Weight',
                        width: 240,
                    },
                    {
                        title: '操作',
                        dataIndex: 'Id',
                        align: 'left',
                        key: 'Id',
                        scopedSlots: {customRender: 'Id'},
                        width: 240,
                    },

                ],
                total: '',
                Page: 0,
                Limit: 8,
                tableList: [],
                pageWidth: '',
                Name: '',
            }
        },
        created() {
            this.pageWidth = document.documentElement.clientWidth - 440;
            this.loadData();
        },
        methods: {
            loadData() {
                this.$message.loading('加载中...', 0);
                let self = this;
                this.$axios.get(1242, {
                    pagesize: self.Limit,
                    p: self.Page,
                    Name: self.Name
                }, res => {
                    this.$message.destroy();

                    if (res.data.code == 0) {
                        self.tableList = res.data.data;
                        self.total = res.data.count;
                    } else {
                        self.tableList = [];
                        self.total = '';
                        this.$message.error(res.data.msg);
                    }
                })
            },
            ChangePage(value){
                this.Page = value;
                this.loadData();
            },
            _reset() {
                this.Name = '';
                this.Page = 0;
                this.loadData();
            },
            _search() {
                this.Page = 0;
                this.loadData();
            },
            _info(id) {
                this.$router.push({path: '/levelAction', query: {id: id}})
            },
            _add(){
                this.$router.push({path: '/levelAction'})
            },
            _del(id) {
                let self = this;
                this.$message.loading('加载中...',0);
                this.$axios.get(1243,{
                    Id:id,
                },res=>{
                    if(res.data.code==0){
                        self.loadData();
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .distributionLevel {
        padding: 20px;
        background: #ffffff;
        width: 100%;
        min-height: 800px;
    }

    .search {
        display: flex;
        margin-bottom: 20px;
        .search-item {
            width: 156px;
            margin-right: 10px;
        }
    }
    .table-action {
        color: #2994FF;
        font-size: 13px;
        margin-right: 15px;
        cursor: pointer;
    }
</style>